// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import LocalTime from 'local-time'
import './controllers'

// font awesome configuration and setup
config.mutateApproach = 'sync'
library.add(faExclamationCircle)
dom.watch() // replace any <i> tags with <svg>

LocalTime.start()

window.sessionStorage.setItem('refresh', 0)

window.Turbo.setProgressBarDelay(1)

if (navigator.serviceWorker) {
  navigator.serviceWorker.register(
    '/service_worker.js', { scope: './' }
  ).then(reg => {
    console.log('[ServiceWorker]', 'Registered!', reg)
  })

  navigator.serviceWorker.addEventListener('message', event => {
    window.app.offline = event.data.msg
  })
}
