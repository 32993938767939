import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  submit (event) {
    event.preventDefault()

    const input = this.inputTarget.value

    window.Turbo.visit(`/sign_in/${input}`)
  }
}
